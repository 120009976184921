import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import NewLayout from "../components/new_layout"

const Suppliers = ({ data, pageContext }) => {
    const page = data.page.edges[0].node
    const elements = {
        p: ({ node, ...props }) => <p className="font s-18 f-regular line-description-hight c-primary" {...props} />
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo lang={pageContext.langKey} title={page.seo.title} description={page.seo.meta_description} translates={pageContext.translates} />

            <main className="main">
                <div className="main__section main__section--100">
                    <div className="container">
                        <h1 className="merriweather">{page.title}</h1>
                        <div className="container__content">
                            <GatsbyImage alt={page.image.alternativeText} image={getImage(page.image.localFile)} />
                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} components={elements} />
                        </div>
                    </div>
                </div>
            </main>
        </NewLayout>
    )
}

export default Suppliers

export const suppliersPageQuery = graphql`
    query ($langKey: String!) {
        page: allStrapiPoliticaDeProveedores ( filter: { locale: { eq: $langKey } } ) {
            edges {
                node {
                    seo {
                        title,
                        meta_description
                    }
                    title
                    description
                    image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 1440
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
